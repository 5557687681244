import { useParams } from 'react-router-dom'
import { DrawerNav, ViewStack } from '../../components'

const Main = (): JSX.Element => {
  const { token } = useParams<{ token: string }>() as { token: string }
  console.log(token)
  return (
    <ViewStack sx={{ width: '100%!important', height: !token && '100%!important' }}>
      <DrawerNav />
    </ViewStack>
  )
}

export default Main
