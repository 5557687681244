import { type GridColDef } from '@mui/x-data-grid'
import { ViewStack, DataGrid, Button, Modal, Form } from '../../components'
import { UserRole } from '../../redux/actions'
import { useUsers } from '../../hooks'
import { Box, TextField } from '@mui/material'
import { useState } from 'react'

const Teachers = (): JSX.Element => {
  const { data } = useUsers(UserRole.TEACHER)
  const [open, setOpen] = useState(false)
  const [load, setLoad] = useState(false)
  const [payload, setPayload] = useState({
    email: '',
    name: '',
    lastName: '',
    address: '',
    phone: '',
    role: 'TEACHER'
  })

  const handleOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const handleChange = (e: any): void => {
    setPayload({ ...payload, [e.target.name]: e.target.value })
  }

  const createTeacher = async (): Promise<void> => {
    setLoad(true)
  }

  const teacherColumns: GridColDef[] = [
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'address', headerName: 'Address', width: 150, valueGetter: (params) => params.row.address || 'N/A' },
    { field: 'phone', headerName: 'Phone', width: 150, valueGetter: (params) => params.row.phone || 'N/A' }
  ]

  return (
    <ViewStack sx={{ padding: '0 12px' }}>
      <Modal open={open} handleClose={handleClose} >
        <Form>
          <h1>Grupo</h1>
          <div style={{ marginTop: '20px', width: '100%' }}>
            <TextField fullWidth id="outlined-basic" label="Nombre" variant="outlined" onChange={handleChange} name='name' />
          </div>
          <div style={{ marginTop: '20px', width: '100%' }}>
            <TextField fullWidth id="outlined-basic" label="Apellido" variant="outlined" onChange={handleChange} name='lastName' />
          </div>
          <div style={{ marginTop: '20px', width: '100%' }}>
            <TextField fullWidth id="outlined-basic" label="Dirección" variant="outlined" onChange={handleChange} name='address' />
          </div>
          <div style={{ marginTop: '20px', width: '100%' }}>
            <TextField fullWidth id="outlined-basic" label="Teléfono" variant="outlined" onChange={handleChange} name='phone' />
          </div>
          <div style={{ marginTop: '20px', width: '100%' }}>
            <TextField fullWidth id="outlined-basic" label="Correo" variant="outlined" onChange={handleChange} name='email' />
          </div>
          <Button onClick={createTeacher} variant="solid" color="#000" backgroundColor="rgba(231, 203, 67, 1)" >
            {load ? 'Creando...' : 'Crear'}
          </Button>
        </Form>
      </Modal>
      <div style={{ marginTop: '20px', width: '100%' }}>
      <Box sx={{ top: '0', display: 'flex', alignItems: 'center' }}>
          <h1>Docentes</h1>
          <Button onClick={handleOpen} variant="solid" width='20%' >
            Crear docente
          </Button>
        </Box>
        <DataGrid rows={data} columns={teacherColumns} />
      </div>
    </ViewStack>
  )
}

export default Teachers
