import * as Yup from 'yup'
import { type FormikValues, useFormik } from 'formik'
import { type StudentPayload } from '../../../interface/StudentForm/studentForm.interface'
import { useAppDispatch, useAppSelector } from '../../../redux/hook'
import { createStudentGroup, getOnlyGrp } from '../../../redux/actions'
import { useEffect } from 'react'

const useStudentForm = (token: string): { steps: string[], formik: FormikValues, loading: boolean, successGrps: boolean, loadingGrps: boolean, success: boolean, data: any } => {
  const { loading, success } = useAppSelector((state) => state.createStudentGrpsReducer)
  const { loading: loadingGrps, success: successGrps, data } = useAppSelector((state) => state.grpsOnlyReducer)
  const dispatch = useAppDispatch()
  const steps = [
    'Datos del Alumno',
    'Datos del la madre',
    'Datos del padre',
    'Datos de salud',
    'Limitaciones físicas y de salud',
    'Contacto de emergencia',
    'Contacto de adicional',
    'Finalizar'
  ]

  const validationSchema = Yup.object({
    email: Yup.string().email('Correo electrónico inválido').required('Correo electrónico es obligatorio'),
    curp: Yup.string().required('CURP es obligatorio'),
    name: Yup.string().required('Nombre es obligatorio'),
    lastName: Yup.string().required('Apellido es obligatorio'),
    address: Yup.string().required('Dirección es obligatoria'),
    group_id: Yup.string(),
    phone: Yup.string().required('Teléfono es obligatorio'),
    password: Yup.string().min(6, 'La contraseña debe tener al menos 6 caracteres').required('Contraseña es obligatoria'),
    emergencyContact: Yup.object({
      name: Yup.string().required('Nombre del contacto de emergencia es obligatorio'),
      phone: Yup.string().required('Teléfono del contacto de emergencia es obligatorio'),
      email: Yup.string().email('Correo electrónico inválido').required('Correo electrónico del contacto de emergencia es obligatorio'),
      address: Yup.string().required('Dirección del contacto de emergencia es obligatoria'),
      relationship: Yup.string().required('Relación con el contacto de emergencia es obligatoria')
    }),
    aditionalContact: Yup.object({
      name: Yup.string().required('Nombre del contacto adicional es obligatorio'),
      phone: Yup.string().required('Teléfono del contacto adicional es obligatorio'),
      email: Yup.string().email('Correo electrónico inválido').required('Correo electrónico del contacto adicional es obligatorio'),
      address: Yup.string().required('Dirección del contacto adicional es obligatoria'),
      relationship: Yup.string().required('Relación con el contacto adicional es obligatoria')
    }),
    birthEntity: Yup.object({
      city: Yup.string().required('Ciudad de nacimiento es obligatoria'),
      country: Yup.string().required('País de nacimiento es obligatorio')
    }),
    hasLimitation: Yup.object({
      physical: Yup.boolean(),
      mental: Yup.boolean(),
      chronic: Yup.boolean(),
      which: Yup.string().when('physical', (physical, schema) => {
        return physical ? schema.required('Especificar la limitación física es obligatorio') : schema
      }),
      description: Yup.string().required('Descripción de la limitación es obligatoria'),
      treatment: Yup.boolean(),
      treatmentDescription: Yup.string().when('treatment', (treatment, schema) => {
        return treatment ? schema.required('Descripción del tratamiento es obligatoria cuando hay tratamiento') : schema
      }),
      controlledMedication: Yup.boolean(),
      medicationDescription: Yup.string().when('controlledMedication', (controlledMedication, schema) => {
        return controlledMedication ? schema.required('Descripción de la medicación es obligatoria cuando hay medicación controlada') : schema
      }),
      whoSuppliesMedication: Yup.string().required('Indicar quién suministra la medicación es obligatorio')
    }),
    healthDetailsDto: Yup.object({
      bloodType: Yup.string().required('Tipo de sangre es obligatorio'),
      alergies: Yup.array().of(Yup.string()).required('Alergias son obligatorias'),
      size: Yup.string().required('Estatura es obligatoria'),
      weight: Yup.string().required('Peso es obligatorio'),
      wearsGlasses: Yup.boolean(),
      wearsHearingAid: Yup.boolean(),
      hasPhysicalLimitation: Yup.boolean(),
      hasMentalLimitation: Yup.boolean(),
      wearsOrthopedicShoes: Yup.boolean()
    }),
    parents: Yup.object({
      mother: Yup.object({
        name: Yup.string().required('Nombre de la madre es obligatorio'),
        dni: Yup.string().required('INE de la madre es obligatorio'),
        phone: Yup.string().required('Teléfono de la madre es obligatorio'),
        address: Yup.string().required('Dirección de la madre es obligatoria'),
        zipCode: Yup.string().required('Código postal de la madre es obligatorio'),
        work: Yup.string().required('Lugar de trabajo de la madre es obligatorio'),
        email: Yup.string().email('Correo electrónico inválido').required('Correo electrónico de la madre es obligatorio'),
        phoneWork: Yup.string().required('Teléfono del trabajo de la madre es obligatorio'),
        civilStatus: Yup.string().required('Estado civil de la madre es obligatorio')
      }),
      father: Yup.object({
        name: Yup.string().required('Nombre del padre es obligatorio'),
        dni: Yup.string().required('INE del padre es obligatorio'),
        phone: Yup.string().required('Teléfono del padre es obligatorio'),
        address: Yup.string().required('Dirección del padre es obligatoria'),
        zipCode: Yup.string().required('Código postal del padre es obligatorio'),
        work: Yup.string().required('Lugar de trabajo del padre es obligatorio'),
        email: Yup.string().email('Correo electrónico inválido').required('Correo electrónico del padre es obligatorio'),
        phoneWork: Yup.string().required('Teléfono del trabajo del padre es obligatorio'),
        civilStatus: Yup.string().required('Estado civil del padre es obligatorio')
      })
    })
  })

  const initialValues: StudentPayload = {
    email: '',
    name: '',
    lastName: '',
    address: '',
    group_id: '',
    phone: '',
    password: '',
    emergencyContact: {
      name: '',
      phone: '',
      email: '',
      address: '',
      relationship: ''
    },
    aditionalContact: {
      name: '',
      phone: '',
      email: '',
      address: '',
      relationship: ''
    },
    birthEntity: {
      city: '',
      country: ''
    },
    hasLimitation: {
      physical: false,
      mental: false,
      chronic: false,
      which: '',
      description: '',
      treatment: false,
      treatmentDescription: '',
      controlledMedication: false,
      medicationDescription: '',
      whoSuppliesMedication: ''
    },
    healthDetailsDto: {
      bloodType: '',
      alergies: [],
      size: '',
      weight: '',
      wearsGlasses: false,
      wearsHearingAid: false,
      hasPhysicalLimitation: false,
      hasMentalLimitation: false,
      wearsOrthopedicShoes: false,
      description: ''
    },
    parents: {
      father: {
        name: '',
        dni: '',
        phone: '',
        address: '',
        zipCode: '',
        work: '',
        email: '',
        phoneWork: '',
        civilStatus: ''
      },
      mother: {
        name: '',
        dni: '',
        phone: '',
        address: '',
        zipCode: '',
        work: '',
        email: '',
        phoneWork: '',
        civilStatus: ''
      }
    },
    curp: ''
  }

  useEffect(() => {
    void dispatch(getOnlyGrp())
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      void dispatch(createStudentGroup(values, token))
      setTimeout(() => {
        resetForm()
      }, 2000)
    }
  })

  return { steps, formik, loading, success, successGrps, loadingGrps, data }
}

export default useStudentForm
