export const USERS = {
  USERS_LOADING: 'USERS_LOADING',
  USERS_SUCCESS: 'USERS_SUCCESS',
  USERS_ERROR: 'USERS_ERROR',
  USERS_RESET: 'USERS_RESET',
  LOGOUT: 'LOGOUT'

}

export const STUDENT = {
  STUDENT_LOADING: 'STUDENT_LOADING',
  STUDENT_SUCCESS: 'STUDENT_SUCCESS',
  STUDENT_ERROR: 'STUDENT_ERROR'
}
export const STUDENT_FORM = {
  STUDENT_LOADING_FORM: 'STUDENT_LOADING_FORM',
  STUDENT_SUCCESS_FORM: 'STUDENT_SUCCESS_FORM',
  STUDENT_ERROR_FORM: 'STUDENT_ERROR_FORM'
}
