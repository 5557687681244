import { veirfyAccount } from '../../../api/services'
import { useState } from 'react'

const useVerifyAccount = (): { fetchVeirfyAccount: (tkn: string) => Promise<void>, isActivated: boolean | null, errorMessage: string } => {
  const [isActivated, setIsActivated] = useState<boolean | null>(null)
  const [errorMessage, setErrorMessage] = useState('')

  const fetchVeirfyAccount = async (tkn: string): Promise<void> => {
    try {
      const res = await veirfyAccount(tkn)
      setIsActivated(res.data.message === 'Se activo la cuenta correctamente')
    } catch (error: any) {
      setErrorMessage(error.response.data.message)
      setIsActivated(false)
    }
  }

  return { fetchVeirfyAccount, isActivated, errorMessage }
}

export default useVerifyAccount
