import { enviroments } from '../env'
import axios, { type AxiosRequestHeaders } from 'axios'

export const api = axios.create({
  baseURL: enviroments.api
})
export const apiAuth = axios.create({
  baseURL: enviroments.api
})

api.interceptors.request.use((config) => {
  const token = JSON.parse(localStorage.getItem('dataUser') as string).access_token
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`
    } as AxiosRequestHeaders
  }
  return config
})

const refreshToken = async (): Promise<string> => {
  try {
    const refreshToken = JSON.parse(localStorage.getItem('dataUser') as string)?.refresh_token
    const body = { refresh_token: refreshToken }
    const response = await axios.post(`${enviroments.api}/auth/refresh`, body)
    const dataStorage = JSON.parse(localStorage.getItem('dataUser') as string)

    const updatedData = {
      ...dataStorage,
      access_token: response.data.access_token,
      refresh_token: response.data.refresh_token
    }
    localStorage.setItem('dataUser', JSON.stringify(updatedData))
    return response.data.access_token
  } catch (error) {
    console.error('Error refreshing token:', error)
    throw error
  }
}

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      try {
        const newToken = await refreshToken()
        originalRequest.headers.Authorization = `Bearer ${newToken}`
        return await api(originalRequest)
      } catch (err) {
        return await Promise.reject(err)
      }
    }
    return await Promise.reject(error)
  }
)
