import { useState } from 'react'
import { styled, useTheme, type Theme, type CSSObject } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded'
import { Avatar, Badge, FormControlLabel } from '@mui/material'
import LunchDiningRoundedIcon from '@mui/icons-material/LunchDiningRounded'
import MeetingRoomRoundedIcon from '@mui/icons-material/MeetingRoomRounded'
import AutoStoriesRoundedIcon from '@mui/icons-material/AutoStoriesRounded'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'

import { MaterialUISwitch } from './styles/styles'
import { useDarkMode } from '../../hooks'
import useAuth from '../../hooks/AuthHooks/useAuth'
import { type DataUSR } from '../../interface'
import { useAppDispatch } from '../../redux/hook'
import { resetAuth } from '../../redux/reducer/Auth/auth.reducer'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('md')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  },
  [theme.breakpoints.down('sm')]: {
    width: 0
  }
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}))

interface DrawerProps {
  open: boolean
  theme: Theme
}

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }: DrawerProps) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
)

export const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""'
    }
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0
    }
  }
}))

interface RtsInterface {
  name: string
  onClick: () => void
  path: string
  icon: JSX.Element
}

const MiniDrawer = (): JSX.Element => {
  const { dataUser }: { dataUser: DataUSR } = useAuth()
  const { darkModeTheme, toggleSwitchDark } = useDarkMode()
  const theme = useTheme()
  const [open, setOpen] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const handleDrawerOpen = (): void => {
    setOpen(!open)
  }

  const navigate = useNavigate()

  const logOut = (): void => {
    localStorage.clear()
    dispatch(resetAuth())
    setTimeout(() => {
      navigate('/login')
      navigate(0)
    }, 900)
  }

  const ButtonFloat = styled(IconButton)(({ theme }) => ({
    position: 'fixed',
    bottom: '3.5rem',
    right: 20,
    borderRadius: 100,
    zIndex: 1,
    color: theme.palette.grey[500],
    backgroundColor: darkModeTheme ? theme.palette.grey[800] : theme.palette.grey[50],
    '&:hover': {
      backgroundColor: darkModeTheme ? theme.palette.grey[800] : theme.palette.grey[50]
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }))

  const myName = `${dataUser.user.name}`

  const routes = (): any => {
    if (dataUser.user.role === 'TEACHER') {
      return [
        {
          name: 'Inicio',
          path: '/',
          icon: <HomeRoundedIcon />,
          onClick: () => { setOpen(false) }
        },
        {
          name: 'Tareas',
          path: '/homeworks',
          icon: <AutoStoriesRoundedIcon />,
          onClick: () => { setOpen(false) }
        },
        {
          name: 'Alumnos',
          path: '/students',
          icon: <PeopleAltRoundedIcon />,
          onClick: () => { setOpen(false) }
        },
        {
          name: 'Planeaciones',
          path: '/planning',
          icon: <CalendarMonthRoundedIcon />,
          onClick: () => { setOpen(false) }
        }
      ]
    } else {
      return [
        {
          name: 'Inicio',
          path: '/',
          icon: <HomeRoundedIcon />,
          onClick: () => { setOpen(false) }
        },
        {
          name: 'Grupos',
          path: '/groups',
          icon: <PersonRoundedIcon />,
          onClick: () => { setOpen(false) }
        },
        {
          name: 'Maestros',
          path: '/teachers',
          icon: <PeopleAltRoundedIcon />,
          onClick: () => { setOpen(false) }
        }
      ]
    }
  }

  return (
    <>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <CssBaseline />
        <Drawer variant="permanent" open={open} theme={theme}>
        <Box marginTop={5}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot">
              <Avatar sx={{ width: !open ? 46 : 70, height: !open ? 46 : 70, fontSize: !open ? 21 : 35 }} alt={myName} src="/static/images/avatar/1.jpg" />
            </StyledBadge>
          </Box>
          <Typography marginTop={3} variant="h6" noWrap component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
            {open && myName}
          </Typography>
        </Box>
          <List>
            {routes().map((text: RtsInterface, index: number) => (
              <Link key={index} onClick={text.onClick} to={text.path} style={{ width: open ? '100%' : 0, transition: 'all 0.3s ease-in-out', textDecoration: 'none', color: 'inherit' }}>
                <ListItem disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center'
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                      <ListItemText primary={text.name} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
          <DrawerHeader>
            <IconButton onClick={handleDrawerOpen}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          </List>
          <Divider />
          <FormControlLabel
            control={<MaterialUISwitch
              onChange={toggleSwitchDark}
            sx={{ m: 1 }}
            checked={darkModeTheme.palette.mode === 'dark'}
            theme={darkModeTheme} />
            } label={undefined} />
          <DrawerHeader sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <IconButton onClick={logOut} >
                <MeetingRoomRoundedIcon color="primary" />
              </IconButton>
            {open && <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
              Cerrar Sesión
            </Typography>
            }
          </DrawerHeader>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, width: '100%' }}>
          <Outlet />
        </Box>
      </Box>
      <ButtonFloat onClick={handleDrawerOpen} >
        <LunchDiningRoundedIcon color="primary" />
      </ButtonFloat>
    </>
  )
}

export default MiniDrawer
