import { type Dispatch } from 'redux'
import { getAllUsers, getStudentId } from '../../../api/services'
import { USERS, STUDENT } from '../../../redux/constants'
import { type AxiosResponse } from 'axios'

export enum UserRole {
  ADMIN = 'ADMIN',
  TEACHER = 'TEACHER',
  STUDENT = 'STUDENT',
  DIRECTOR = 'DIRECTOR',
  ROOT = 'ROOT',

}
export const getUsers = (role: UserRole) => async (dispatch: any): Promise<any> => {
  dispatch({ type: USERS.USERS_LOADING })
  const token = JSON.parse(localStorage.getItem('dataUser') as string).access_token
  try {
    const res: AxiosResponse<any> = await getAllUsers(token, role)
    dispatch({ type: USERS.USERS_SUCCESS, payload: res.data.data })
  } catch (error) {
    dispatch({ type: USERS.USERS_ERROR, payload: error })
  }
}

export const getStudent = (id: string) => async (dispatch: Dispatch): Promise<any> => {
  dispatch({ type: STUDENT.STUDENT_LOADING })
  const token = JSON.parse(localStorage.getItem('dataUser') as string).access_token
  try {
    const res: AxiosResponse<any> = await getStudentId(token, id)
    dispatch({ type: STUDENT.STUDENT_SUCCESS, payload: res.data.user })
  } catch (error: any) {
    dispatch({ type: STUDENT.STUDENT_ERROR, payload: error.message })
  }
}
