import { Grid, Typography } from '@mui/material'
import { type FormikValues } from 'formik'
import { FormField } from '../styles/styles'
import { InputC } from '../../../components'

const LimitationForm = ({ formik }: FormikValues): JSX.Element => {
  return (
    <>
      <Typography variant="h5" component="h4" mb={5}>
        Limitaciones físicas y psicológicas
      </Typography>
        <FormField>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <InputC
              label="¿Tiene alguna limitación física?"
              name="hasLimitation.physical"
              type="checkbox"
              value={formik.values.hasLimitation.physical || false}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.hasLimitation?.physical && formik.errors.hasLimitation?.physical}
            />
              <InputC
                label="¿Tiene alguna limitación pscicológica?"
                name="hasLimitation.mental"
                type="checkbox"
                value={formik.values.hasLimitation.mental || false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.hasLimitation?.mental && formik.errors.hasLimitation?.mental}
            />
              <InputC
                label="Descripción"
                name="hasLimitation.description"
                type="text"
                value={formik.values.hasLimitation.description || ''}
                onChange={formik.handleChange}
                placeholder='Describa la limitación psicológica'
                onBlur={formik.handleBlur}
                helperText={formik.touched.hasLimitation?.description && formik.errors.hasLimitation?.description}
              />
            </Grid>
          <Grid item xs={12} sm={4}>
              <InputC
                label="¿Tiene alguna enfermedad crónica?"
                name="hasLimitation.chronic"
                type="checkbox"
                value={formik.values.hasLimitation.chronic || false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.hasLimitation?.chronic && formik.errors.hasLimitation?.chronic}
            />
              <InputC
                label="¿Cuál?"
                name="hasLimitation.which"
                type="text"
                value={formik.values.hasLimitation.which || ''}
                onChange={formik.handleChange}
                placeholder='Ejemplo: Diabetes, Asma, silla de ruedas, etc...'
                onBlur={formik.handleBlur}
                helperText={formik.touched.hasLimitation?.which && formik.errors.hasLimitation?.which}
            />
              <InputC
                label="¿Está recibiendo tratamiento?"
                name="hasLimitation.treatment"
                type="checkbox"
                value={formik.values.hasLimitation.treatment || false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.hasLimitation?.treatment && formik.errors.hasLimitation?.treatment}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputC
              label="¿Está tomando medicación controlada?"
              name="hasLimitation.controlledMedication"
              type="checkbox"
              value={formik.values.hasLimitation.controlledMedication || false}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.hasLimitation?.controlledMedication && formik.errors.hasLimitation?.controlledMedication}
            />
            <InputC
              label="¿Quién suministra la medicación?"
              name="hasLimitation.whoSuppliesMedication"
              type="text"
              value={formik.values.hasLimitation.whoSuppliesMedication || ''}
              onChange={formik.handleChange}
              placeholder='Ejemplo: Doctor, Familiar'
              onBlur={formik.handleBlur}
              helperText={formik.touched.hasLimitation?.whoSuppliesMedication && formik.errors.hasLimitation?.whoSuppliesMedication}
            />
            <InputC
                label="Descripción"
                name="hasLimitation.description"
                type="text"
                value={formik.values.hasLimitation.description || ''}
                onChange={formik.handleChange}
                placeholder='Describa la limitación física'
                onBlur={formik.handleBlur}
                helperText={formik.touched.hasLimitation?.description && formik.errors.hasLimitation?.description}
              />
            </Grid>
            {formik.values.hasLimitation.treatment && (
              <Grid item xs={12}>
                <InputC
                  label="Descripción del tratamiento"
                  name="hasLimitation.treatmentDescription"
                  type="text"
                  value={formik.values.hasLimitation.treatmentDescription || ''}
                  onChange={formik.handleChange}
                  placeholder='Describa el tratamiento'
                  onBlur={formik.handleBlur}
                  helperText={formik.touched.hasLimitation?.treatmentDescription && formik.errors.hasLimitation?.treatmentDescription}
                />
              </Grid>
            )}
            {formik.values.hasLimitation.controlledMedication && (
              <Grid item xs={12}>
                <InputC
                  label="Descripción de la medicación"
                  name="hasLimitation.medicationDescription"
                  type="text"
                  value={formik.values.hasLimitation.medicationDescription || ''}
                  onChange={formik.handleChange}
                  placeholder='Describa la medicación'
                  onBlur={formik.handleBlur}
                  helperText={formik.touched.hasLimitation?.medicationDescription && formik.errors.hasLimitation?.medicationDescription}
                />
              </Grid>
            )}
          </Grid>
      </FormField>
    </>
  )
}

export default LimitationForm
