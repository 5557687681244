import { Button, Card, CardActions, CardContent, CardHeader, Grid, MenuItem, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'

const AssignmentsSection = (): JSX.Element => {
  const [file, setFile] = useState<File | null>(null)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files?.[0]) {
      setFile(event.target.files[0])
    }
  }

  const validationSchema = Yup.object({
    title: Yup.string().required('El título es requerido'),
    description: Yup.string().required('La descripción es requerida'),
    subject: Yup.string().required('La materia es requerida'),
    dueDate: Yup.date().required('La fecha de entrega es requerida'),
    file: Yup.mixed().required('El archivo es requerido')
  })

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      subject: '',
      dueDate: '',
      file: null
    },
    validationSchema,
    onSubmit: (values) => {
      console.log('Form values', values)
    }
  })

  useEffect(() => {
    console.log('Errors', formik.errors)
    console.log('Touched', formik)
  }, [formik.errors, formik])

  return (
    <Card>
      <CardHeader title="Crear Nueva Tarea o Actividad" />
      <CardContent>
        <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Título de la tarea" variant="outlined" name={'title'} value={formik.values.title} onChange={formik.handleChange} />
              {formik.errors.title && formik.touched.title && (
                <Typography variant="body2" color="error">
                  {formik.errors.title}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                label="Seleccionar materia"
                variant="outlined"
                name={'subject'}
                value={formik.values.subject}
              >
                <MenuItem value="spanish">Español</MenuItem>
                <MenuItem value="english">Inglés</MenuItem>
                <MenuItem value="art">Artes</MenuItem>
                <MenuItem value="geography">Geografía</MenuItem>
                <MenuItem value="history">Historia</MenuItem>
                <MenuItem value="ethics">Formación Cívica y Ética</MenuItem>
                <MenuItem value="math">Matemáticas</MenuItem>
                <MenuItem value="biology">Biología</MenuItem>
                <MenuItem value="chemistry">Química</MenuItem>
                <MenuItem value="physics">Física</MenuItem>
                <MenuItem value="technology">Tecnología</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Descripción de la tarea"
                variant="outlined"
                name={'description'}
                value={formik.values.description}
                onChange={formik.handleChange}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Fecha de entrega"
              variant="outlined"
              margin="normal"
              type="date"
              InputLabelProps={{ shrink: true }}
              name="dueDate"
              onChange={formik.handleChange}
            />
            </Grid>
            <Grid item xs={12} sm={6}>
              <input type="file" onChange={handleFileChange} />
              {file && (
                <Typography variant="body2" color="error">
                  <Typography fontSize="small" style={{ verticalAlign: 'middle' }}> El archivo adjunto estará disponible por 1 mes, después se borrará automáticamente del sistema.
                </Typography>
                </Typography>
              )}
            </Grid>
          </Grid>
          <CardActions>
            <Button variant="contained" color="primary" type="submit">
              Crear Tarea
            </Button>
          </CardActions>
        </form>
      </CardContent>
    </Card>
  )
}

export default AssignmentsSection
