import React, { useEffect, useState } from 'react'
import { Box, Grid, Paper, Typography, Button, TextField, MenuItem, InputLabel, Select } from '@mui/material'
import EventIcon from '@mui/icons-material/Event'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import { getOnlyGrp } from '../../redux/actions'
import useHome from './hooks/useHome'

const Home: React.FC = () => {
  const { events: restEvn, createAllEvent, createEventsRes } = useHome()
  const dispatch = useAppDispatch()
  const { loading: loadingGrps, success: successGrps, data } = useAppSelector((state) => state.grpsOnlyReducer)
  const [events, setEvents] = useState<Array<{
    title: string
    date: string
    description: string
    group_id: string
  }>>([])

  const [newEvent, setNewEvent] = useState({
    title: '',
    date: '',
    description: '',
    group_id: ''
  })

  const handleChange = (e: any): void => {
    const { name, value } = e.target
    setNewEvent((prevEvent) => ({
      ...prevEvent,
      [name]: value
    }))
  }

  useEffect(() => {
    void dispatch(getOnlyGrp())
  }, [])

  useEffect(() => {
    setEvents(restEvn.data)
  }, [restEvn])

  const handleCreateEvent = (): void => {
    const dataStorage = JSON.parse(localStorage.getItem('dataUser') as string)
    if (newEvent.title && newEvent.date && newEvent.description && newEvent.group_id) {
      const payload = {
        title: newEvent.title,
        date: newEvent.date,
        description: newEvent.description,
        group_id: newEvent.group_id === 'all' ? '' : newEvent.group_id,
        createdBy_id: dataStorage.user._id
      }
      void createAllEvent(payload)
    }
  }

  useEffect(() => {
    if (createEventsRes.success) {
      setEvents([...events, newEvent])
      setNewEvent({
        title: '',
        date: '',
        description: '',
        group_id: ''
      })
    }
  }, [createEventsRes.success])

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h6">Pagos de Mensualidad</Typography>
            <Typography variant="h3" color="primary">75%</Typography>
            <Typography variant="body2" color="error">25% Pendientes</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h6">Total de Alumnos</Typography>
            <Typography variant="h3">1,234</Typography>
            <Typography variant="body2">Alumnos registrados</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h6">Maestros Activos</Typography>
            <Typography variant="h3">56</Typography>
            <Typography variant="body2">Maestros en plantilla</Typography>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h6">Crear Evento</Typography>
            <TextField
              fullWidth
              label="Nombre del Evento"
              variant="outlined"
              margin="normal"
              name="title"
              onChange={handleChange}
              value={newEvent.title}
            />
            <TextField
              fullWidth
              label="Descripción del Evento"
              variant="outlined"
              margin="normal"
              name="description"
              onChange={handleChange}
              value={newEvent.description}
            />
            <TextField
              fullWidth
              label="Fecha del Evento"
              variant="outlined"
              margin="normal"
              type="date"
              InputLabelProps={{ shrink: true }}
              name="date"
              onChange={handleChange}
              value={newEvent.date}
            />
            <div style={{ marginTop: '20px', width: '100%' }}>
              {loadingGrps && <Typography variant="body2">Cargando grupos...</Typography>}
              {successGrps &&
                <><InputLabel id="group_id">Selecciona una opción</InputLabel><Select id="group_id" fullWidth variant="outlined" onChange={handleChange} name='group_id' value={newEvent.group_id}>
                <MenuItem value="">Selecciona una opción</MenuItem>
                <MenuItem value="all">Toda la escuela</MenuItem>
                  {[...data.data]
                    .sort((a: any, b: any) => a.name - b.name)
                    .map((text: any) => (
                      <MenuItem value={text.id} key={text.id}>
                        {text.grade} {text.group} - {text.name}
                      </MenuItem>
                    ))
                  }
                </Select>
                </>
              }
            </div>
            {/* <TextField
              fullWidth
              select
              label="Tipo de Evento"
              variant="outlined"
              margin="normal"
              name="eventType"
              onChange={handleChange}
              value={newEvent.eventType}
            >
              <MenuItem value="Toda la escuela">Toda la escuela</MenuItem>
              <div style={{ marginTop: '20px', width: '100%' }}>
                {loadingGrps && <Typography variant="body2">Cargando grupos...</Typography>}
                <div style={{ marginTop: '20px', width: '100%' }}>
                  {successGrps &&
                    [...data.data]
                      .sort((a: any, b: any) => a.name - b.name)
                      .map((text: any) => (
                        <MenuItem value={text.id} key={text.id}>
                          {text.grade} {text.group} - {text.name}
                        </MenuItem>
                      ))
                  }
                </div>
              </div>
            </TextField> */}
            <Button variant="contained" color="primary" fullWidth onClick={handleCreateEvent}>
              Crear Evento
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h6">Actividades de Maestros</Typography>
            <Box display="flex" alignItems="center" mb={1}>
              <Typography variant="body1">Juan Pérez - 1º A</Typography>
              <CheckCircleIcon color="success" sx={{ ml: 2 }} />
            </Box>
            <Box display="flex" alignItems="center" mb={1}>
              <Typography variant="body1">María González - 2º B</Typography>
              <CheckCircleIcon color="success" sx={{ ml: 2 }} />
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="body1">Roberto López - 3º A</Typography>
              <CancelIcon color="error" sx={{ ml: 2 }} />
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Typography variant="h4" gutterBottom>
        Próximos Eventos
      </Typography>

      <Paper sx={{ padding: 2, marginBottom: 2 }}>
        {events.length === 0 && (
          <Typography variant="body2" mt={2}>
            No hay eventos próximos
          </Typography>
        )}
        {events.length > 0 && (
          events.map((event, index) => (
            <Box display="flex" alignItems="center" mt={2} key={index}>
              <EventIcon color="primary" />
              <Box ml={2}>
                <Typography variant="h6">{event.title}</Typography>
                <Typography variant="body2">{new Date(event.date).toLocaleDateString()}</Typography>
              </Box>
            </Box>
          ))
        )}
      </Paper>
    </Box>
  )
}

export default Home
