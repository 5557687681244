import { type FormikValues } from 'formik'
import { Grid, Typography } from '@mui/material'
import { InputC } from '../../../components'
import { FormField } from '../styles/styles'

const HealthDetailsForm = ({ formik }: FormikValues): JSX.Element => {
  const healthDetails = formik.values.healthDetailsDto || {}

  return (
    <>
      <Typography variant="h5" component="h4" mb={5}>
        Datos de salud
      </Typography>
        <FormField>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
              <InputC
                type="text"
                name="healthDetailsDto.bloodType"
                fullWidth
                placeholder="Ingresa tu tipo de sangre aquí"
                label="Tipo de sangre"
                variant="outlined"
                value={healthDetails.bloodType || ''} // Maneja valores undefined
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.healthDetailsDto?.bloodType && formik.errors.healthDetailsDto?.bloodType}
              />
              <InputC
                type="text"
                name="healthDetailsDto.size"
                fullWidth
                placeholder="Ingresa tu estatura aquí"
                label="Estatura en cm"
                variant="outlined"
                value={healthDetails.size || ''} // Maneja valores undefined
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.healthDetailsDto?.size && formik.errors.healthDetailsDto?.size}
            />
              <InputC
                type="text"
                name="healthDetailsDto.weight"
                fullWidth
                placeholder="Ingresa tu peso aquí"
                label="Peso en kg"
                variant="outlined"
                value={healthDetails.weight || ''} // Maneja valores undefined
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.healthDetailsDto?.weight && formik.errors.healthDetailsDto?.weight}
              />
          </Grid>
          <Grid item xs={12} sm={4}>
              <InputC
                type="text"
                name="healthDetailsDto.alergies"
                fullWidth
                placeholder="Ingresa tus alergias aquí: alergia1, alergia2, ..."
                label="Alergias"
                variant="outlined"
                value={healthDetails.alergies.join(', ') || ''} // Maneja valores undefined
                onChange={(e) => formik.setFieldValue('healthDetailsDto.alergies', e.target.value.split(', '))}
                onBlur={formik.handleBlur}
                helperText={formik.touched.healthDetailsDto?.alergies && formik.errors.healthDetailsDto?.alergies}
            />
              <InputC
                name="healthDetailsDto.wearsGlasses"
                placeholder="Ingresa si usas lentes aquí"
                type="checkbox"
                label="Usa lentes"
                variant="outlined"
                value={healthDetails.wearsGlasses || false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.healthDetailsDto?.wearsGlasses && formik.errors.healthDetailsDto?.wearsGlasses}
              />
              <InputC
                type="checkbox"
                name="healthDetailsDto.wearsHearingAid"
                placeholder="Ingresa si usas aparato auditivo aquí"
                label="Usa aparato auditivo"
                variant="outlined"
                value={healthDetails.wearsHearingAid || false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.healthDetailsDto?.wearsHearingAid && formik.errors.healthDetailsDto?.wearsHearingAid}
              />
            </Grid>
          <Grid item xs={12} sm={4}>
              <InputC
                type="checkbox"
                name="healthDetailsDto.hasPhysicalLimitation"
                fullWidth
                placeholder="Ingresa si tienes alguna limitación física aquí"
                label="Limitación física"
                variant="outlined"
                value={healthDetails.hasPhysicalLimitation || false} // Maneja valores undefined
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.healthDetailsDto?.hasPhysicalLimitation && formik.errors.healthDetailsDto?.hasPhysicalLimitation}
              />
              <InputC
                type="checkbox"
                name="healthDetailsDto.wearsOrthopedicShoes"
                fullWidth
                placeholder="Ingresa si usas zapatos ortopédicos aquí"
                label="Usa zapatos ortopédicos"
                variant="outlined"
                value={healthDetails.wearsOrthopedicShoes || false} // Maneja valores undefined
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.healthDetailsDto?.wearsOrthopedicShoes && formik.errors.healthDetailsDto?.wearsOrthopedicShoes}
            />
              <InputC
                type="checkbox"
                name="healthDetailsDto.hasMentalLimitation"
                fullWidth
                placeholder="Ingresa si tienes alguna limitación mental aquí"
                label="Discapacidad intelectual o de aprendizaje"
                variant="outlined"
                value={healthDetails.hasMentalLimitation || false} // Maneja valores undefined
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.healthDetailsDto?.hasMentalLimitation && formik.errors.healthDetailsDto?.hasMentalLimitation}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InputC
              type="textarea"
              name="healthDetailsDto.description"
              fullWidth
              placeholder="Describe"
              label="Descripción"
              variant="outlined"
              value={healthDetails.description || ''} // Maneja valores undefined
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.healthDetailsDto?.description && formik.errors.healthDetailsDto?.description}
            />
          </Grid>
      </FormField>
    </>
  )
}

export default HealthDetailsForm
