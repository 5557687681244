import { getEvents, createEvent } from '../../../api/services'
import { useEffect, useState } from 'react'

const useHome = (): { events: { success: boolean, data: any[] }, createAllEvent: (payload: any) => Promise<void>, createEventsRes: { success: boolean, data: any[] } } => {
  const [events, setEvents] = useState({
    success: false,
    data: []
  })
  const [createEventsRes, setCreateEvents] = useState({
    success: false,
    data: [],
    error: ''
  })

  const getAllEvents = async (): Promise<void> => {
    const event = await getEvents()
    try {
      setEvents({
        success: true,
        data: event.data
      })
    } catch (error) {
      setEvents({
        success: false,
        data: []
      })
    }
  }

  const createAllEvent = async (payload: any): Promise<any> => {
    const event = await createEvent(payload)
    try {
      setCreateEvents({
        success: true,
        data: event.data,
        error: ''
      })
    } catch (error) {
      console.log(error)
      setCreateEvents({
        success: false,
        data: [],
        error: error as string
      })
    }
  }

  useEffect(() => {
    void getAllEvents()
  }, [])

  return { events, createAllEvent, createEventsRes }
}

export default useHome
