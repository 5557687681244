import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { STUDENT } from '../../constants'
import { type StudentResult } from '@/interface'

interface IState {
  loading: boolean
  error: null | string
  data: StudentResult | null
  success: boolean
}

const initialState: IState = {
  loading: false,
  error: null,
  data: null,
  success: false
}

const studentReducer = createSlice({
  name: 'studentReducer',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(STUDENT.STUDENT_LOADING, (state) => {
        state.loading = true
      })
      .addCase(STUDENT.STUDENT_SUCCESS, (state, action: PayloadAction<StudentResult>) => {
        state.loading = false
        state.success = true
        state.data = action.payload
      })
      .addCase(STUDENT.STUDENT_ERROR, (state, action: PayloadAction<string>) => {
        state.loading = false
        state.error = action.payload
      })
  },
  reducers: {
    resetStudent: () => initialState
  }
})

export const { resetStudent } = studentReducer.actions

export default studentReducer.reducer
