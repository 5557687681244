import { type StudentPayload } from '@/interface/StudentForm/studentForm.interface'
import { api, apiAuth } from '../../config'

const getGroups = async (limit = 5, offset = 0): Promise<any> => await api.get(`/groups?limit=${limit}&offset=${offset}`)

const getEvents = async (): Promise<any> => await api.get('/groups/events')

const getOnlyGroups = async (): Promise<any> => await apiAuth.get('/groups/only')

const createGroup = async (payload: any): Promise<any> => await api.post('/groups', payload)

const getGroup = async (id: string | undefined): Promise<any> => await api.get(`/groups/${id}`)

const createStudentGrpService = async (payload: StudentPayload, token: string): Promise<any> => await apiAuth.post('/groups/student', payload, { headers: { Authorization: `Bearer ${token}` } })

const createEvent = async (payload: any): Promise<any> => await api.post('/groups/events', payload)

export { getGroups, createGroup, getGroup, createStudentGrpService, getOnlyGroups, createEvent, getEvents }
