import type React from 'react'
import { useState } from 'react'
import { useAppSelector } from '../../../redux/hook'
import { type FormikProps } from 'formik'

interface PropsSteps {
  isStepOptional: (step: number) => boolean
  isStepSkipped: (step: number) => boolean
  handleNext: () => void
  handleBack: () => void
  handleReset: () => void
  hasErrorsInCurrentStep: () => boolean
  errorReducer: any
}

const useSteps = (activeStep: number, setActiveStep: React.Dispatch<React.SetStateAction<number>>,
  formik?: FormikProps<any>): PropsSteps => {
  const { error: errorReducer } = useAppSelector((state) => state.createStudentGrpsReducer)
  const [skipped, setSkipped] = useState(new Set<number>())

  const isStepOptional = (step: number): boolean => {
    return step === 1
  }

  const isStepSkipped = (step: number): boolean => {
    return skipped.has(step)
  }

  const handleNext = (): void => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = (): void => {
    setActiveStep(0)
  }

  const hasErrorsInCurrentStep = (): boolean => {
    if (!formik?.errors) {
      return false
    }

    const stepFieldsMap: any = {
      0: ['name', 'lastName', 'curp', 'email', 'password', 'birthEntity.city', 'birthEntity.country', 'phone', 'address'],
      1: ['parents.mother.name', 'parents.mother.dni', 'parents.mother.phone', 'parents.mother.address', 'parents.mother.zipCode', 'parents.mother.work', 'parents.mother.email', 'parents.mother.phoneWork', 'parents.mother.civilStatus'],
      2: ['parents.father.name', 'parents.father.dni', 'parents.father.phone', 'parents.father.address', 'parents.father.zipCode', 'parents.father.work', 'parents.father.email', 'parents.father.phoneWork', 'parents.father.civilStatus'],
      3: ['healthDetailsDto.bloodType', 'healthDetailsDto.alergies', 'healthDetailsDto.size', 'healthDetailsDto.weight', 'healthDetailsDto.wearsGlasses', 'healthDetailsDto.wearsHearingAid', 'healthDetailsDto.hasPhysicalLimitation', 'healthDetailsDto.hasMentalLimitation', 'healthDetailsDto.wearsOrthopedicShoes'],
      4: ['hasLimitation.physical', 'hasLimitation.mental', 'hasLimitation.chronic', 'hasLimitation.which', 'hasLimitation.description', 'hasLimitation.treatment', 'hasLimitation.treatmentDescription', 'hasLimitation.controlledMedication', 'hasLimitation.medicationDescription', 'hasLimitation.whoSuppliesMedication'],
      5: ['emergencyContact.name', 'emergencyContact.phone', 'emergencyContact.email', 'emergencyContact.address', 'emergencyContact.relationship'],
      6: ['aditionalContact.name', 'aditionalContact.phone', 'aditionalContact.email', 'aditionalContact.address', 'aditionalContact.relationship']
    }

    const currentStepFields: string[] = stepFieldsMap[activeStep] || []
    return currentStepFields.some((field) => {
      const fieldPath = field.split('.')
      let error: any | string = formik.errors

      for (const path of fieldPath) {
        if (error[path]) {
          console.log('ERROR', error)
          error = error[path]
        } else {
          return false
        }
      }

      return typeof error === 'string' && error.length > 0
    })
  }

  return {
    isStepOptional,
    isStepSkipped,
    handleNext,
    handleBack,
    handleReset,
    hasErrorsInCurrentStep,
    errorReducer
  }
}

export default useSteps
