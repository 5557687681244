import { Paper } from '@mui/material'

const PaperComponent = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return (
    <Paper sx={{ borderRadius: '25px', width: '100%', maxWidth: '800px', margin: 'auto', overflow: 'hidden' }} elevation={24} style={{ padding: '0px', backdropFilter: 'blur(10px)', WebkitBackdropFilter: 'blur(10px)', backgroundColor: 'rgba(255, 255, 255, 0.3)' }}>
      {children}
    </Paper>
  )
}

export default PaperComponent
