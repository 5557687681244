import { api, apiAuth } from '../../config'
import { type UserRole } from '@/redux/actions/users/users.action'

export const getAllUsers = async (token: string, role?: UserRole): Promise<any> => await api.get(`/users?role=${role}`, {
  headers:
    {
      Authorization: `Bearer ${token}`
    }
})
export const getStudentId = async (token: string, id: string): Promise<any> => await api.get(`/users/student/${id}`, {
  headers:
    {
      Authorization: `Bearer ${token}`
    }
})

export const createStudentForm = async (token: string, payload: any): Promise<any> => await api.post('/users/student', payload, {
  headers:
    {
      Authorization: `Bearer ${token}`
    }
})

export const veirfyAccount = async (token: string): Promise<any> => await apiAuth.put(`/users/verify_account/${token}`)
