import { useEffect } from 'react'
import {
  Container,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Box
} from '@mui/material'
import { CheckCircle, Error } from '@mui/icons-material'
import { green, red } from '@mui/material/colors'
import { useParams } from 'react-router-dom'
import useVerifyAccount from './hooks/useVerifyAccount'
import google_play from '../../assets/images/google_play.png'
import app_store from '../../assets/images/app_store.png'

const ActivateAccount = (): JSX.Element => {
  const { token } = useParams<{ token: string }>() as { token: string }
  const { fetchVeirfyAccount, isActivated, errorMessage } = useVerifyAccount()
  useEffect(() => {
    void fetchVeirfyAccount(token)
  }, [])

  if (isActivated === null) {
    return (
      <Container maxWidth="sm" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    )
  }

  return (
    <Container maxWidth="sm" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Paper elevation={3} sx={{ p: 4, textAlign: 'center' }}>
        {isActivated
          ? (
            <Box>
              <CheckCircle sx={{ fontSize: 60, color: green[500], mb: 2 }} />
              <Typography variant="h5" gutterBottom>
                ¡Cuenta Activada Correctamente!
              </Typography>
              <Typography variant="body1" paragraph>
                Tu cuenta ha sido activada con éxito. Ahora puedes disfrutar de todos los beneficios de nuestro servicio.
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" paragraph>
                  Descarga nuestra app para disfrutar de una mejor experiencia.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                  <Button variant="outlined" onClick={() => window.open('https://apps.apple.com/app/colegio-miramar-app-students/id6657961102')} sx={{ border: 'nonde' }} >
                    <img src={google_play} alt="Google Play" style={{ width: 120 }} />
                  </Button>
                  <Button variant="outlined" onClick={() => window.open('https://apps.apple.com/app/colegio-miramar-app-students/id6657961102')} sx={{ border: 'nonde' }}>
                    <img src={app_store} alt="App Store" style={{ width: 120 }} />
                  </Button>
                </Box>
              </Box>
            </Box>
            )
          : (
            <Box>
              <Error sx={{ fontSize: 60, color: red[500], mb: 2 }} />
              <Typography variant="h5" gutterBottom>
                No se pudo activar la cuenta
              </Typography>
              <Typography variant="body1" paragraph>
                Lo sentimos, ha ocurrido un error al activar tu cuenta. Por favor, intenta nuevamente o contacta a nuestro equipo de soporte.
              </Typography>
              <Typography variant="body1" paragraph>
                {errorMessage}
              </Typography>
              {/* <Button variant="contained" color="primary" sx={{ mr: 2 }}>
                Reintentar
              </Button>
              <Button variant="outlined" color="secondary">
                Contactar Soporte
              </Button> */}
            </Box>
            )}
      </Paper>
    </Container>
  )
}

export default ActivateAccount
