import { Avatar, Box } from '@mui/material'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useStudent } from '../../hooks'
import { TabPanel, Tabs, ViewStack, Skeleton } from '../../components'
import { useState } from 'react'
import mascota from '../../assets/images/mascota.png'

const Section = styled.div`
  width: 100%;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 0 15px;
`

const SectionTitle = styled.h2`
  font-size: 18px;
  margin-bottom: 10px;
`

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #808080;
`

const InfoLabel = styled.div`
  font-weight: bold;
`

const InfoValue = styled.div`
`

const Student = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const { data: user, loading, error } = useStudent(String(id))
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue)
  }

  if (!user && !loading && !error) return <Skeleton />
  if (error) return <div>Error: {error}</div>

  return (
    <ViewStack sx={{ padding: '0 12px', flexDirection: 'column' }} alignItems='center'>
      <div style={{ marginTop: '20px', width: '100%' }}>
        <h1>Expediente del Alumno:</h1>
      </div>
      <Box sx={{ display: 'flex', alignItems: 'center', margin: '20px 0' }}>
        <Avatar sx={{ width: 170, height: 170, fontSize: 35, backgroundColor: '#65b2d6' }} src={mascota} sizes='large' imgProps={{ sx: { objectFit: 'scale-down', width: '90%' } }} />
      </Box>
      <Box flexWrap={
        { xs: 'wrap' }
      } sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px', width: '100%' }}>
        <Tabs labels={['Información Personal', 'Información del Grupo', 'Información de Salud']}
          handleChange={handleChange} value={value} setValue={setValue} >
          <TabPanel value={value} index={0}>
            <Section>
              <SectionTitle>Información Personal</SectionTitle>
              <InfoRow>
                <InfoLabel>Nombre:</InfoLabel>
                <InfoValue>{user.name} {user.lastName}</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Email:</InfoLabel>
                <InfoValue>{user.email}</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Teléfono:</InfoLabel>
                <InfoValue>{user.phone}</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Domicilio:</InfoLabel>
                <InfoValue>{user.address}</InfoValue>
              </InfoRow>
            </Section>
            <Section>
              <SectionTitle>Información del Padre, Madre o Tutor</SectionTitle>
              <InfoRow>
                <InfoLabel>Padre:</InfoLabel>
                <InfoValue>{user.parents ? user.parents.father.name : 'No tiene padre registrado'}</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Estado Civil del Padre:</InfoLabel>
                <InfoValue>{user.parents ? user.parents.father.civilStatus === 'MARRIED_HE' ? 'Casado' : 'Soltero' : 'No tiene padre registrado'}</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Madre:</InfoLabel>
                <InfoValue>{user.parents ? user.parents.mother.name : 'No tiene madre registrada'}</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Estado Civil:</InfoLabel>
                <InfoValue>{user.parents ? user.parents.mother.civilStatus === 'MARRIED_HE' ? 'Casada' : 'Soltera' : 'No tiene madre registrada'}</InfoValue>
              </InfoRow>
            </Section>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Section>
              <SectionTitle>Información del Grupo</SectionTitle>
              <InfoRow>
                <InfoLabel>Grado:</InfoLabel>
                <InfoValue>{user.group_id?.grade}</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Grupo:</InfoLabel>
                <InfoValue>{user.group_id.group}</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Nombre del Grupo:</InfoLabel>
                <InfoValue>{user.group_id.name}</InfoValue>
              </InfoRow>
            </Section>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Section>
              <SectionTitle>Información de Salud</SectionTitle>
              <InfoRow>
                <InfoLabel>Tipo de Sangre:</InfoLabel>
                <InfoValue>{user.healthDetailsDto.bloodType}</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Alergias:</InfoLabel>
                <InfoValue>{user.healthDetailsDto.alergies.join(', ')}</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Estatura:</InfoLabel>
                <InfoValue>{user.healthDetailsDto.size} m</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Peso:</InfoLabel>
                <InfoValue>{user.healthDetailsDto.weight} kg</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Usa Lentes:</InfoLabel>
                <InfoValue>{user.healthDetailsDto.wearsGlasses ? 'Sí' : 'No'}</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Limitación Física:</InfoLabel>
                <InfoValue>{user.hasLimitation.physical ? user.hasLimitation.description : 'No'}</InfoValue>
              </InfoRow>
            </Section>
          </TabPanel>
        </Tabs>
      </Box>
    </ViewStack>
  )
}

export default Student
