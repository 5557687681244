import { type ViewStackProps } from '@/components/ViewStack/styles/styles'
import { styled, Button } from '@mui/material'

export const StyledButton = styled(Button)<ViewStackProps>(({ theme, backgroundColor, color, width }) => ({
  padding: theme.spacing(1.0),
  width: width ?? '100%',
  borderRadius: theme.spacing(1.5),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: backgroundColor ?? theme.palette.primary.main,
  color: color ?? theme.palette.primary.contrastText,
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark
  }
}))
