import { useEffect } from 'react'
import { useAppSelector } from '../../redux/hook'
import { getGrpId } from '../../redux/actions'
import { useAppDispatch } from '../../redux/tpye_hooks'
import { resetGroups } from '../../redux/reducer/Groups/groupsReducer'

const useGroup = (id: string | undefined): any => {
  const { data: users, loading } = useAppSelector((state: { groupReducer: any }) => state.groupReducer)
  const dispatch = useAppDispatch()

  const dataGroups = async (): Promise<void> => {
    await dispatch(getGrpId(id))
  }

  useEffect(() => {
    if (id) {
      void dataGroups().then(() => { })
    }
    return () => {
      dispatch(resetGroups())
    }
  }, [id, dispatch])

  return {
    users,
    loading
  }
}

export default useGroup
