import * as Yup from 'yup'
import { type ObjectSchema } from 'yup'
import toast from 'react-hot-toast'
import { useFormik } from 'formik'
import { useAppDispatch, useAppSelector } from '../../../redux/hook'
import { signIn } from '../../../redux/actions'

const useForm = (): { initialValues: typeof initialValues, validationSchema: ObjectSchema<typeof initialValues>, formik: any, loading: boolean, success: boolean } => {
  const dispatch = useAppDispatch()

  const { loading, success } = useAppSelector((state) => state.authReducer)
  const validationSchema: ObjectSchema<typeof initialValues> = Yup.object().shape({
    email: Yup.string().required('E-mail obrigatório').email('E-mail inválido'),
    password: Yup.string()
      .required('El password es obligatorio')
      .min(3, 'No mínimo 3 dígitos')
  })

  const initialValues = {
    email: '',
    password: ''
  }

  const notifyPromise = (promise: any): void => {
    void toast.promise(promise, {
      loading: 'Loading...',
      success: 'Signed Up!',
      error: 'Error signing up'
    })
  }

  const onSubmit = (values: typeof initialValues): void => {
    void dispatch(signIn(values, notifyPromise))
  }

  const formik: any = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values: typeof initialValues) => {
      onSubmit(values)
    }
  })

  return {
    initialValues,
    validationSchema,
    formik,
    loading,
    success
  }
}

export default useForm
