import styled from 'styled-components'
import { Field } from 'formik'
import { Typography } from '@mui/material'

const StyledForm = styled('form')`
  width: 100%;
  margin: 8px auto;
  padding: 20px;
`

const FormField = styled.div`
  margin-bottom: 20px;
`

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`

const Input = styled(Field)`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 5px;
`

// const Checkbox = styled(Field)`
//   margin-right: 10px;
// `

const Button = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`

const ErrorMessageStyled = styled.div`
  color: red;
  margin-top: 5px;
`

const TitleStyled = styled(Typography)`
  font-family: "Luckiest Guy", cursive!important;
  font-weight: 400;
  font-style: normal;
  font-size: 3rem!important;
  line-height: 1!important;
  margin-top: 5rem!important;
  text-align: center;
  margin-bottom: 1rem;
  color: #fff;
`

export { StyledForm, FormField, Label, Input, Button, ErrorMessageStyled, TitleStyled }
