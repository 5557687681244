import { useEffect } from 'react'
import EmailRoundedIcon from '@mui/icons-material/EmailRounded'
import FingerprintRoundedIcon from '@mui/icons-material/FingerprintRounded'
import { useNavigate } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'

import { ViewStackUi } from './styles/style'
import useForm from './hooks/useFom'
import { Button, Form, InputC, ViewStack } from '../../components'
import { useAuth } from '../../hooks'
import BackgroundMiramar from '../../assets/images/login-view/backgroundLogin.svg'

const SignUp = (): JSX.Element => {
  const { formik, loading } = useForm()
  const navigate = useNavigate()
  const { status } = useAuth()

  useEffect(() => {
    if (status) {
      navigate('/')
    }
  }, [status])

  return (
    <ViewStackUi backgroundColor='#E6F3FF'>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <ViewStack important paddingX={4} width={0.40} margin={'auto'}>
        <div>
          <img width={'80%'} src={BackgroundMiramar} alt="Logo Miramar" />
        </div>
      </ViewStack>

      <ViewStack important paddingX={4} width={0.6} margin={'auto'}>
        <form onSubmit={formik.handleSubmit} style={{ width: '70%' }}>
          <Form>
            <InputC fullWidth placeholder="Ingresa tu correo electrónico aquí" label="Correo electrónico" name="email" variant="outlined" startDecorator={<EmailRoundedIcon />} value={formik.values.email} onChange={formik.handleChange} />
            <InputC fullWidth placeholder="Ingresa tu contraseña aquí" label="Contraseña" name="password" variant="outlined" type="password" startDecorator={<FingerprintRoundedIcon />} value={formik.values.password} onChange={formik.handleChange} />
            <Button type="submit" variant="solid" backgroundColor="rgba(231, 203, 67, 1)" color="#000" width='60%' >
              {loading ? 'Loading...' : 'Iniciar Sesion'}
            </Button>
          </Form>
        </form>
      </ViewStack>

    </ViewStackUi>
  )
}

export default SignUp
