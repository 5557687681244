import styled from '@emotion/styled'
import Box from '@mui/material/Box'

export interface ViewStackProps {
  /**
     * The number of views in the stack.
     * This is used to calculate the height of the stack.
     * @default 0
     * */
  width?: number
  margin?: string | number
  alignItems?: string | undefined
  viewHeight?: number | null
  important?: boolean
  backgroundColor?: string | undefined
  color?: string | undefined
  gradient?: string | undefined
}

export const ViewStack = styled(Box)<ViewStackProps>`
    display: flex;
    justify-content: center;
    margin: ${({ margin }) => (margin != null) ? margin : 'auto'};
    width: ${({ width }) => (width != null) ? `${width * 100}%` : '100%'};
    height: ${({ viewHeight }) => (viewHeight != null) ? `${viewHeight * 100}vh` : '100%'};
    align-items: ${({ alignItems }) => (alignItems != null) ? alignItems : 'center'};
    transition: transform 0.3s ease-in-out;
    background-image: ${({ gradient }) => (gradient != null) ? gradient : 'none'};
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: 600px) {
        width: ${({ important }) => (important !== null) && '100vw!important'};
        height: 200vh;
    }

`
