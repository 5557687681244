import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'
import App from '../App'
import {
  Teachers, Groups, SignUp, NotFound, Students, Student, StudentForm, Redirect,
  PoliticsandTerms, Home, RegistroMaestro, ActivateAccount, HomeWorks, HomeTeacher, StudentsView, ClassRoomPlaner, StudentGrading
} from '../Views'
import Private from './Private'
import { useAuth } from '../hooks'

const teacherRoutes = (
  <>
    <Route path="/" element={<App />}>
      <Route index path="/" element={<HomeTeacher />} />
      <Route path="homeworks" element={<HomeWorks />} />
      <Route path="students" element={<StudentsView />} />
      <Route path="planning" element={<ClassRoomPlaner />} />
      <Route path="student-act" element={<StudentGrading />} />
    </Route>
  </>
)

const studentRoutes = (
  <>
    <Route path="/" element={<App />}>
      <Route index path="/" element={<Home />} />
      <Route path="groups" element={<Groups />} />
      <Route path="teachers" element={<Teachers />} />
      <Route path="groups/:id" element={<Students />} />
      <Route path="student/:id" element={<Student />} />
    </Route>
  </>
)

const AppRouter = (): JSX.Element => {
  const { status, dataUser } = useAuth()

  const routes = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route element={<Private auth={{ isAuthenticated: status }} />}>
          {dataUser.user.role === 'TEACHER' ? teacherRoutes : studentRoutes}
        </Route>

        <Route path="politica" element={<PoliticsandTerms />} />
        <Route path="user/verify_account/:token" element={<ActivateAccount />} />
        <Route path="login" element={<SignUp />} />
        <Route path="registro" element={<StudentForm />} />
        <Route path="registro_docente" element={<RegistroMaestro />} />
        <Route path="/:shortUrl" element={<Redirect />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    )
  )

  return <RouterProvider router={routes} />
}

export const Routes = (): JSX.Element => <AppRouter />
