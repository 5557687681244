import { Button, TextField, Box, Typography, Container, Paper } from '@mui/material'
import axios from 'axios'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import logoMiramar from '../../assets/images/logo_miramar.png'

interface TeacherPayload {
  name: string
  lastName: string
  email: string
  contrasena: string
  telefono: string
  domicilio: string
  assignedGroupId: string
  identificacion: File | null
  cedula: File | null
  birthdate: string
  curp: string
  rfc: string
  nss: string
}

const validationSchema = Yup.object({
  name: Yup.string().required('El nombre es obligatorio'),
  lastName: Yup.string().required('El apellido es obligatorio'),
  email: Yup.string().email('Correo electrónico no válido').required('El correo es obligatorio'),
  contrasena: Yup.string().min(8, 'La contraseña debe tener al menos 8 caracteres').required('La contraseña es obligatoria'),
  telefono: Yup.string().required('El teléfono es obligatorio'),
  domicilio: Yup.string().required('El domicilio es obligatorio'),
  assignedGroupId: Yup.string().required('El grupo asignado es obligatorio'),
  birthdate: Yup.string().required('La fecha de nacimiento es obligatoria'),
  curp: Yup.string().required('El CURP es obligatorio'),
  rfc: Yup.string().required('El RFC es obligatorio'),
  nss: Yup.string().required('El NSS es obligatorio'),
  identificacion: Yup.mixed().required('La identificación oficial es obligatoria')
})

const RegistroMaestro = (): JSX.Element => {
  const crearProfesor = async (payload: TeacherPayload): Promise<any> => {
    try {
      const formData = new FormData()

      // Agregar los datos de texto al FormData
      formData.append('name', payload.name)
      formData.append('lastName', payload.lastName)
      formData.append('email', payload.email)
      formData.append('contrasena', payload.contrasena)
      formData.append('telefono', payload.telefono)
      formData.append('domicilio', payload.domicilio)
      formData.append('assignedGroupId', payload.assignedGroupId)
      formData.append('birthdate', payload.birthdate)
      formData.append('curp', payload.curp)
      formData.append('rfc', payload.rfc)
      formData.append('nss', payload.nss)

      // Agregar archivos si están presentes
      if (payload.identificacion) {
        formData.append('identificacion', payload.identificacion)
      }
      if (payload.cedula) {
        formData.append('cedula', payload.cedula)
      }

      // Hacer la petición con Axios
      const response = await axios.post(
        'https://miramar-school-backend-develop.up.railway.app/api/v1/users/teacher',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )

      return response.data
    } catch (error) {
      console.error('Error al crear el profesor:', error)
      throw error
    }
  }

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column" sx={{ width: '100%' }}>
      <Box component="header" bgcolor="#243d99" color="primary.contrastText" py={4} sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Container sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100px' }}>
          <img src={logoMiramar} alt="Colegio Miramar" width="80" />
        </Container>
        <Container sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography variant="h4" fontWeight="bold">Registro de Docentes</Typography>
        </Container>
      </Box>
      <Container maxWidth="sm">
        <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
          <Formik
            initialValues={{
              name: '',
              lastName: '',
              email: '',
              contrasena: '',
              telefono: '',
              domicilio: '',
              assignedGroupId: '664c8f8d520dc9f41f49191f',
              birthdate: '',
              curp: '',
              rfc: '',
              nss: '',
              identificacion: null,
              cedula: null
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              console.log('Enviando formulario:', values)
              try {
                await crearProfesor(values)
              } catch (error) {
                console.error('Error al enviar el formulario', error)
              } finally {
                setSubmitting(false)
              }
            }}
          >
            {({ setFieldValue, touched, errors }) => (
              <Form>
                <Field name="name" as={TextField} label="Nombre" fullWidth required error={touched.name && Boolean(errors.name)} helperText={touched.name && errors.name} style={{ marginBottom: '1rem' }} />
                <Field name="lastName" as={TextField} label="Apellido" fullWidth required error={touched.lastName && Boolean(errors.lastName)} helperText={touched.lastName && errors.lastName} style={{ marginBottom: '1rem' }} />
                <Field name="email" as={TextField} label="Correo electrónico" fullWidth required error={touched.email && Boolean(errors.email)} helperText={touched.email && errors.email} style={{ marginBottom: '1rem' }} />
                <Field name="contrasena" as={TextField} label="Contraseña" type="password" fullWidth required error={touched.contrasena && Boolean(errors.contrasena)} helperText={touched.contrasena && errors.contrasena} style={{ marginBottom: '1rem' }} />
                <Field name="telefono" as={TextField} label="Teléfono" fullWidth required error={touched.telefono && Boolean(errors.telefono)} helperText={touched.telefono && errors.telefono} style={{ marginBottom: '1rem' }} />
                <Field name="domicilio" as={TextField} label="Domicilio" fullWidth required error={touched.domicilio && Boolean(errors.domicilio)} helperText={touched.domicilio && errors.domicilio} style={{ marginBottom: '1rem' }} />
                <Field name="" as={TextField} label="Grupo Asignado" fullWidth required error={touched.assignedGroupId && Boolean(errors.assignedGroupId)} helperText={touched.assignedGroupId && errors.assignedGroupId} style={{ marginBottom: '1rem' }} />
                <Field name="birthdate" as={TextField} label="Fecha de Nacimiento" fullWidth required error={touched.birthdate && Boolean(errors.birthdate)} helperText={touched.birthdate && errors.birthdate} style={{ marginBottom: '1rem' }} />
                <Field name="curp" as={TextField} label="CURP" fullWidth required error={touched.curp && Boolean(errors.curp)} helperText={touched.curp && errors.curp} style={{ marginBottom: '1rem' }} />
                <Field name="rfc" as={TextField} label="RFC" fullWidth required error={touched.rfc && Boolean(errors.rfc)} helperText={touched.rfc && errors.rfc} style={{ marginBottom: '1rem' }} />
                <Field name="nss" as={TextField} label="NSS" fullWidth required error={touched.nss && Boolean(errors.nss)} helperText={touched.nss && errors.nss} style={{ marginBottom: '1rem' }} />

                <Box mb={4}>
                  <Typography variant="body1" gutterBottom>Identificación Oficial</Typography>
                  <input
                    id="identificacion"
                    name="identificacion"
                    type="file"
                    onChange={(event) => {
                      const file = event.currentTarget.files?.[0]
                      void setFieldValue('identificacion', file)
                    }}
                    accept="image/*,.pdf"
                    required
                  />
                  {touched.identificacion && Boolean(errors.identificacion) && (
                    <Typography variant="body2" color="error">
                      {errors.identificacion}
                    </Typography>
                  )}
                </Box>

                <Box mb={4}>
                  <Typography variant="body1" gutterBottom>Cédula profesional (opcional)</Typography>
                  <input
                    id="cedula"
                    name="cedula"
                    type="file"
                    onChange={(event) => {
                      const file = event.currentTarget.files?.[0]
                      void setFieldValue('cedula', file)
                    }}
                    accept="image/*,.pdf"
                  />
                </Box>

                <Button type="submit" variant="contained" color="primary" fullWidth>
                  Registrarse
                </Button>
              </Form>
            )}
          </Formik>
        </Paper>
      </Container>
    </Box>
  )
}

export default RegistroMaestro
