import { Input, FormControl } from '@mui/joy'
import { styled } from '@mui/material'

export const InputUi = styled(Input)(({ theme }) => ({
}))

export const FormControlUi = styled(FormControl)(({ theme }) => ({
  '& .MuiInput-root': {
    padding: '0.8rem',
    borderRadius: '18px',
    backgroundColor: 'rgba(176, 186, 195, 0.4)',
    border: 'rgba(176, 186, 195, 0.4)',
    '&:hover': {
      backgroundColor: 'rgba(176, 186, 195, 0.6)'
    },
    '&:focus': {
      backgroundColor: 'rgba(176, 186, 195, 0.6)'
    }
  }
}))
