import { GridToolbar, type GridColDef } from '@mui/x-data-grid'
import Typography from '@mui/material/Typography'
import { Link, useParams } from 'react-router-dom'
import { DataGrid, ViewStack } from '../../components'
import { useGroup } from '../../hooks'

const Students = (): JSX.Element => {
  const { id } = useParams()
  const { users, loading } = useGroup(id)

  const columns: GridColDef[] = [
    { field: 'lastName', headerName: 'Apellido', width: 150 },
    { field: 'name', headerName: 'Nombre', width: 150 },
    { field: 'email', headerName: 'Correo', width: 200 },
    {
      field: 'curp',
      headerName: 'Curp',
      width: 200,
      renderCell: (params) => {
        return (
          <Typography>
            {params.row.curp || 'No disponible'}
          </Typography>
        )
      }
    },
    {
      field: 'phone',
      headerName: 'Teléfono',
      width: 200,
      renderCell: (params) => {
        return (
          <Typography>
            {params.row.phone || 'No disponible'}
          </Typography>
        )
      }
    },
    {
      field: 'show',
      headerName: 'Ver',
      width: 200,
      renderCell: (params) => {
        return (
          <div>
            <Link style={{ textDecoration: 'none', color: 'inherit' }}
              to={`/student/${params.row._id}`}>Ver alumno
            </Link>
          </div>
        )
      }
    }
  ]

  return (
    <ViewStack sx={{ padding: '0 12px' }} alignItems='flex-start'>
      <div style={{ marginTop: '20px', width: '100%' }}>
        <h1>Alumnos</h1>
        {!loading && users && (
          <DataGrid
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true
              }
            }}
          rows={users?.students_id || []}
          columns={columns}
          getRowId={(row: any) => row._id}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5
              }
            }
          }}
          pageSizeOptions={[11]}
          disableRowSelectionOnClick
          loading={loading}
        />
        )}
      </div>
    </ViewStack>
  )
}

export default Students
